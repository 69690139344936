<template>
  <div id="">
    <CCard class="shadow-sm rounded">
      <CCardBody>
        <div class="form-group">
          <input
            type="search"
            class="form-control col-3"
            placeholder="Try typing 'CSU' or Caraga State University"
            v-model="searchValue"
          />
        </div>

        <div class="table-responsive">
          <table id="table-of-heis" class="table table-sm table-hover d mt-4">
            <thead class="">
              <tr>
                <th>LOGO</th>
                <th>ABBR</th>
                <th>NAME OF INSTITUTION</th>
                <th>CITY</th>
                <th>EMAIL</th>
                <th>CONTACT NO</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="hei in filteredHeis"
                :key="hei.code"
                @click.stop="pushRoute(hei)"
              >
                <td scope="row">
                  <img :src="hei.logo" width="60" heigh="60" />
                </td>
                <td>
                  {{ hei.abbrev }}
                </td>
                <td>
                  {{ hei.name }}
                </td>
                <td>{{ hei.city }}</td>
                <td>{{ hei.email }}</td>
                <td>{{ hei.contact_no }}</td>
                <td>
                  <span
                    class="badge"
                    :class="
                      hei.status == 'Active' ? 'badge-success' : 'badge-danger'
                    "
                  >
                    {{ hei.status }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Heis',
  components: {},
  data() {
    return {
      heis: '',
      searchValue: '',
    };
  },
  methods: {
    pushRoute(hei) {
      this.$router.push({
        name: 'HeiProfile',
        params: {
          id: hei.code,
          hei_data: hei,
        },
      });
    },

    getHeis() {
      if (this.$store.state.heis.length) {
        return;
      }
      axios
        .get('api/heis')
        .then((response) => {
          this.$store.state.heis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    filteredHeis() {
      let temp = this.$store.state.heis;

      if (this.searchValue != '') {
        temp = temp.filter((hei) => {
          return (
            hei['abbrev']
              .toUpperCase()
              .includes(this.searchValue.toUpperCase()) ||
            hei['name'].toUpperCase().includes(this.searchValue.toUpperCase())
          );
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getHeis();
  },
};
</script>

<style scoped>
#table-of-heis tr {
  cursor: pointer;
}
</style>
